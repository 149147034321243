import { Link } from 'react-router-dom';

type TButtonProps = {
  toUrl: string, 
  state?: any, 
  className?: string,
  children: JSX.Element | null,
}

export default function Button({ 
  toUrl, 
  state = {}, 
  children = null,
  className = '',
}: TButtonProps ) {
  return (
    <Link
        className={`btn btn-block w-100 rounded-5 fw-semibold ${className}`}
        to={toUrl}
        state={state}
    >
      {children}
    </Link>
  )
}
