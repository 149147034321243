import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import PosterUiKit from 'poster-ui-kit';

import ConnectSpot from './ConnectSpot';
import ConnectedSpot from './ConnectedSpot';
import { TAccountSpotStatus } from '../../constants/accountSpotStatus';

export default function SpotDetail() {
  const location = useLocation();
  const data = location.state?.data;
  const navigate = useNavigate();

  if (!data) {
    return Navigate({to: '/'});;
  }

  const { hubsterStoreId, address, companyName, status, spotName } = data;
  let childComponent = <ConnectedSpot />;

  if (status === TAccountSpotStatus.ACCOUNT_STATUS_CONNECTING) {
    childComponent = (
        <ConnectSpot spot={{
          hubsterStoreId: hubsterStoreId,
          companyName: companyName,
          address: address,
        }}/>
    );
  }

  return (
    <>
      <PosterUiKit.PageHeader
        title={`Hubster — ${spotName}`}
        backUrl="/"
        onBackClick={(e: any) => {
          e.preventDefault();
          navigate('/');
        }}
      />
      <div className="container col-12 col-sm-10 col-md-8 text-center">
        {childComponent}
      </div>
    </>
  );
};
