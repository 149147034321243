import en from '../languages/en/hubster.json';
import mx from '../languages/mx/hubster.json';
import ua from '../languages/ua/hubster.json';

const langs = { en, mx, ua };

/**
 * Language format
 *
 * На вход принимает ключ перевода, и N фраз
 * Пример:
 *  ключ: title = "Статус заведения %s"
 *  вызов: l(title, 'Жилянская')
 *  результат: Статус заведения Жилянская
 *
 * @param {string} index
 * @param words
 * @returns
 */
export default function l(index, ...words) {
  const activeLang = window.config && window.config.lang ? window.config.lang : 'mx',
    lang = langs[activeLang] ?? langs['en'];
  let str;

  str = lang[index];

  if (typeof str === 'undefined' || str === null || str.trim(str.toString()) === '') {
    str = index;
  }

  if (words.length > 0) {
    for (let i = 0; i < words.length; i++) {
      str = str.replace('%s', words[i]);
    }
  }
  
  return str;
}
