import l from '../../helpers/language';

export default function ConnectedSpot() {
  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <img width="176" height="176" className="rounded-4" src="/hubster-logo.png" alt=""/>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h3>{ l('manage.connectedStoreInfoPage.title') }</h3>
        </div>
      </div>
      <div className="row mt-2">
        <div 
          className="col-sm-8 mx-auto small text-secondary"
          dangerouslySetInnerHTML={{ __html: l('manage.connectedStoreInfoPage.description') }}
        />
      </div>
      <div className="row mt-3">
        <div className="col">
          <a 
            className="btn btn-success" 
            href="https://manager.tryotter.com" 
            target="_blank"
            rel="noreferrer"
          >
            { l('manage.connectedStoreInfoPage.buttonText') }
          </a>
        </div>
      </div>
    </>
  );
}
