import { useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
// @ts-ignore
import PosterUiKit from 'poster-ui-kit';

import { TAccountSpotStatus } from '../../constants/accountSpotStatus';
import l from '../../helpers/language';
import Button from './Button';

type TState = {
  companyName: string,
  spots: {
    id: string,
    name: string,
    address: string,
    hubsterStoreId: string,
    status: number,
  }[],
}

export default function SpotList() {
  const [state, setSpots] = useState<TState>({
    companyName: '',
    spots: [],
  });

  useEffect( () => {
    (async () => {
      const spotsResponse = await axios.get('/client/spots');
      setSpots(spotsResponse.data);
    })()
  }, []);

  function getButtonText(spotStatus: number) {
    switch (spotStatus) {
      case  TAccountSpotStatus.ACCOUNT_STATUS_CONNECTING:
        return l('manage.listing.buttonConnectText');

      default:
        return l('manage.listing.buttonConnectedText');
    }
  }

  return (
    <div>
      <PosterUiKit.PageHeader
        title="Hubster"
      />
      <div className="row px-4 pb-2 pt-3 border-bottom text-secondary fs-7 small">
        <div className="col-6 col-sm-10">{l('manage.listing.colSpotName')}</div>
        <div className="col-6 col-sm-2 text-center">{l('manage.listing.colStatusName')}</div>
      </div>
      {state.spots.map((spot) => {
        return (
            <div key={spot.id} className="row px-4 py-2 border-bottom">
              <div className="col-6 col-sm-10 align-self-center">{spot.name}</div>
              <div className="col-6 col-sm-2">
                <Button
                  className={
                    classNames({
                      'btn-primary': spot.status === TAccountSpotStatus.ACCOUNT_STATUS_CONNECTING,
                      'btn-outline-primary': spot.status !== TAccountSpotStatus.ACCOUNT_STATUS_CONNECTING,
                    })
                  }
                  toUrl={`/${spot.id}`}
                  state={{
                    data: {
                      address: spot.address,
                      hubsterStoreId: spot.hubsterStoreId,
                      companyName: state.companyName,
                      status: spot.status,
                      spotName: spot.name
                    }
                  }}
                >
                  { getButtonText(spot.status) }
                </Button>
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}
