import PropTypes from 'prop-types';
import l from '../../helpers/language';

export default function ConnectSpot(props: { spot: { hubsterStoreId: string; companyName: string, address: string }; }) {
  const { hubsterStoreId, companyName, address } = props.spot;

  const emailTemplate = l('manage.sendRequestForm.emailExample', hubsterStoreId, companyName, address);
  const clearHtmlDiv = document.createElement('div');
  clearHtmlDiv.innerHTML = emailTemplate.replace(/<br \/>/g, '\n');
  const mailToBody = encodeURIComponent(clearHtmlDiv.innerText || clearHtmlDiv.textContent || '');

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <img width="176" height="176" className="rounded-4" src="/hubster-logo.png" alt=""/>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h3>{l('manage.sendRequestForm.title')}</h3>
        </div>
      </div>
      <div className="row mt-2">
        <div
          className="col-sm-8 mx-auto small"
          dangerouslySetInnerHTML={{__html: l('manage.sendRequestForm.description')}}
        />
      </div>
      <div className="row mt-2">
        <div className="col-sm-8 mx-auto">
          <div 
            className="text-start mb-1" 
            dangerouslySetInnerHTML={{__html: l('manage.sendRequestForm.emailExampleTitle')}}
          />
          <div 
            className="p-2 bg-light rounded-3 text-start text-secondary" 
            dangerouslySetInnerHTML={{__html: emailTemplate}}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <a
            className="btn btn-success"
            href={`mailto:hola@gohubster.com?subject=Connect%20to%20hubster%20from%20poster&body=${mailToBody}`}
          >
            {l('manage.sendRequestForm.buttonText')}
          </a>
        </div>
      </div>
    </>
  );
};

ConnectSpot.propTypes = {
  spot: PropTypes.shape({
    hubsterStoreId: PropTypes.string,
    companyName: PropTypes.string,
    address: PropTypes.string,
  })
};

