import React, { useEffect } from 'react';
import './App.css';
import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";

import SpotList from './components/spotList/SpotList';
import SpotDetail from './components/spotDetail/SpotDetail';
import axios from 'axios';

declare global {
  interface Window {
    config: { lang: string; };
  }
}

function App() {
  useEffect(() => {
    window.top?.postMessage({ hideSpinner: true }, '*');
    (async () => {
      const accountResponse = await axios.get('/client/settings');
      window.config = { lang: 'en' };
      window.config.lang = accountResponse.data?.lang;
    })();
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route index element={<SpotList />}/>
        <Route path='/:id' element={<SpotDetail />}/>
        <Route path='*' element={<SpotList />}/>
      </Routes>
    </HashRouter>
  );
}

export default App;
